import React, { Component } from 'react'

export const EventDataContext = React.createContext()

class EventDataProvider extends Component {
  

  state = {
    eventData: localStorage.getItem('eventData') ? JSON.parse(localStorage.getItem('eventData')) : {galleryImages:[],ctaList:[],videoList:[],locationList:[],vehicleList:[],additionalFields:[]}
  }

  // Method to update state
  setEventData = (eventData) => {
    this.setState((prevState) => ({ eventData }))
  }

  render() {
    const { children } = this.props
    const { eventData } = this.state
    const { setEventData } = this

    return (
      <EventDataContext.Provider
        value={{
            eventData,
          setEventData,
        }}
      >
        {children}
      </EventDataContext.Provider>
    )
  }
}

// export default EventDataContext

export { EventDataProvider }