import firebase from '../firebase'

export const authMethods = {

    signup: (email, password, setErrors, setUser) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
            //make res asynchonous so that we can make grab the user before saving it.
            .then(async res => {
                const user = await Object.entries(res.user)[5][1].b
                //set user to localStorage 
                await localStorage.setItem('user', user)
                setUser(user)
                //grab user from local storage and set to state. 
                // console.log(res)
            })
            .catch(err => {
                setErrors(prev => ([...prev, err.message]))
            })
    },
    signin: (email, password, setErrors, setUser)  => {
        //change from create users to...
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password).then(cred => {
                cred.user.getIdTokenResult()
                    .then((idTokenResult) => {
                        let u = { isAuthenticated: true, email:email, user: idTokenResult.user, role: idTokenResult.claims.role, groups: idTokenResult.claims.groups,token: idTokenResult.token };
                        localStorage.setItem('user', JSON.stringify(u));
                        setUser(u);
                        
                    });

            }).catch(function (error) {
                localStorage.setItem('user', JSON.stringify({}));
                setUser({});
                setErrors(prev => ([...prev, error.message]))

                // console.log(error);
            });
    },
    //no need for email and password
    signout: (setErrors, setUser) => {
        // signOut is a no argument function
        firebase.auth().signOut().then(res => {
            //remove the user
            localStorage.setItem('user',"{}")
            //set the user back to original state
            setUser({})
        })
            .catch(err => {
                //there shouldn't every be an error from firebase but just in case
                setErrors(prev => ([...prev, err.message]))
                //whether firebase does the trick or not i want my user to do there thing.
                localStorage.removeItem('user')
                setUser(null)
                console.error(err.message)
            })
    },
}