import React,{ useContext } from 'react';

import { EventDataContext } from "../EventDataContext"


export const EventName = () => {
    const { eventData, setEventData } = useContext(EventDataContext);

    
    return (
      <span className="d-none d-md-inline">{eventData.sysname ? eventData.sysname : eventData.name}</span>
    );
};
export default EventName;